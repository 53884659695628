<template>
  <a-layout id="app-layout" :class="collapsed ? 'collapsed' : null">
    <a-layout-sider
      breakpoint="lg"
      collapsed-width="0"
      v-model="collapsed"
      collapsible
      :trigger="null"
    >
      <a-menu theme="dark" mode="inline" :selected-keys="selectedKeys">
        <a-menu-item key="Home">
          <router-link :to="{ name: 'Home' }">
            <a-icon type="home" /> <span>查询</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="Charge">
          <router-link :to="{ name: 'Charge' }">
            <a-icon type="pay-circle" /> <span>充值</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="Code">
          <router-link :to="{ name: 'Code' }">
            <a-icon type="fork" /> <span>分销</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="Help">
          <router-link :to="{ name: 'Help' }">
            <a-icon type="question-circle" /> <span>帮助</span>
          </router-link>
        </a-menu-item>
        <a-sub-menu key="sub1">
          <span slot="title"> <a-icon type="setting" /><span>设置</span> </span>

          <a-menu-item key="ChangePassword">
            <router-link :to="{ name: 'ChangePassword' }">
              <a-icon type="key" /> <span>修改密码</span>
            </router-link>
          </a-menu-item>
          <a-menu-item key="Logout">
            <router-link :to="{ name: 'Logout' }">
              <a-icon type="logout" /> <span>退出</span>
            </router-link>
          </a-menu-item>
        </a-sub-menu>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header
        :style="{
          padding: 0,
          background: '#fff'
        }"
      >
        <a-page-header
          :title="routeTitle"
          @back="() => (collapsed = !collapsed)"
        >
          <template slot="backIcon">
            <a-icon
              class="trigger"
              :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            />
          </template>
        </a-page-header>
      </a-layout-header>
      <a-layout-content
        :style="{
          background: '#fff',
          minHeight: '280px'
        }"
      >
        <slot />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
// const moment = require("moment");

import { mapState, mapMutations } from "vuex";

const UserSvc = require("@/service/user");

const ROUTE_TITLE = {
  Index: "首页",

  Home: "查询",
  Charge: "充值",
  Code: "分销",
  Help: "帮助",
  ChangePassword: "修改密码"
};

export default {
  data() {
    return {
      title: process.env.VUE_APP_TITLE,

      collapsed: false
    };
  },

  computed: {
    ...mapState({
      user: state => state.user
    }),

    selectedKeys() {
      return [this.$route.name];
    },

    routeTitle() {
      return ROUTE_TITLE[this.$route.name];
    }
  },

  methods: {
    ...mapMutations(["setUserInfo"]),

    logout() {
      this.$confirm({
        title: "提示",
        content: () => <div>确认退出系统吗？</div>,
        okText: "确认退出",
        onOk: async () => {
          // 跳转登录
          this.$router.replace({ name: "Logout" });
        },
        onCancel() {}
      });
    }
  },

  async mounted() {
    try {
      await UserSvc.check();
    } catch (err) {
      console.error(err);
      this.$message.error(err.message);

      // 错误处理
      this.$router.replace({ name: "Login" });
      return;
    }

    try {
      const info = await UserSvc.info();
      this.setUserInfo(info);
    } catch (err) {
      console.error(err);
      this.$message.error("加载用户信息失败，请刷新页面");
    }
  }
};
</script>

<style lang="scss">
#app-layout {
  // .trigger {
  //   font-size: 18px;
  //   line-height: 64px;
  //   padding: 0 24px;
  //   cursor: pointer;
  //   transition: color 0.3s;

  //   &:hover {
  //     color: #1890ff;
  //   }
  // }

  .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }

  .user-box {
    color: #fff;
    text-align: center;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    font-size: 18px;
  }

  .userInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .avatar {
    }
    .name {
      max-width: 100px;
      line-height: 1em;
      height: 1em;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .btn {
      margin-left: 20px;
    }
  }

  .ant-layout-sider {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
  }

  .ant-layout {
    margin-left: 200px;
    min-height: 100vh;
    transition: all cubic-bezier(0.18, 0.89, 0.32, 1.28) 0.5s;
  }

  .ant-layout-content {
    padding: 10px;
  }

  &.collapsed {
    .ant-layout {
      margin-left: 0;
    }
  }
}
</style>
