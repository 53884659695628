import Vue from "vue";
// import Antd from "ant-design-vue";

import App from "./App.vue";
import router from "./Router.js";
import store from "./store";

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

// Vue.use(Antd);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
