import { default as request } from "./request";

/**
 * 登录
 */
export const login = data => request.post("/login", data);

/**
 * 注册
 */
export const register = data => request.post("/register", data);
