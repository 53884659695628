<template>
  <div class="app-register">
    <a-page-header title="注册" />
    <div>
      <a-form-model class="form-link" :model="form" ref="form">
        <a-form-model-item
          required
          label="Email"
          prop="username"
          :rules="[
            {
              required: true,
              message: 'Email必填'
            },
            {
              min: 2,
              message: 'Email至少2个字符'
            },
            {
              max: 60,
              message: '账号最多60个字符'
            }
          ]"
        >
          <a-input v-model="form.username" />
        </a-form-model-item>

        <a-form-model-item
          required
          label="密码"
          prop="password"
          :rules="[
            {
              required: true,
              message: '密码必填'
            },
            {
              min: 6,
              message: '密码至少6个字符'
            },
            {
              max: 32,
              message: '密码最多32个字符'
            }
          ]"
        >
          <a-input-password v-model="form.password" />
        </a-form-model-item>

        <a-button type="primary" @click="submit" block size="large"
          >提交</a-button
        >
      </a-form-model>
    </div>
  </div>
</template>

<script>
const LoginSvc = require("@/service/login");

export default {
  name: "Register",

  data() {
    return {
      form: {
        username: "",
        password: ""
      }
    };
  },

  methods: {
    async submit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            await LoginSvc.register(this.form);
            this.$message.info("注册成功");

            // 跳转首页
            this.$router.replace({ name: "Login" });
          } catch (err) {
            console.error(err);
            this.$message.error("提交失败，错误：" + err.message);
          }
        } else {
          console.log("error submit!!");

          return false;
        }
      });
    }
  },

  components: {}
};
</script>

<style lang="scss">
.app-register {
  width: 100%;
  height: 100vh;
  padding: 20px;
}
</style>
