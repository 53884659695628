import request from "./request";

/**
 * 检查登录有效性
 */
export const check = () => request.get("/user/check");

/**
 * 注销
 */
export const logout = () => request.get("/user/logout");

/**
 * 获取用户信息
 */
export const info = () => request.get("/user/info");

/**
 * 改密码
 */
export const change = data => request.post("/user/change", data);
