<template>
  <Layout>
    <div class="app-help">
      <center>
        <h2>微信</h2>
        <p>
          <img style="width:200px" src="@/assets/kf.png" />
        </p>
      </center>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

export default {
  data() {
    return {
      title: process.env.VUE_APP_TITLE
    };
  },

  methods: {},

  components: {
    Layout
  }
};
</script>

<style lang="scss">
.app-help {
  font-size: 18px;

  h2 {
    margin-top: 50px;

    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
